import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "about",
  "title": "Chi siamo",
  "pretitle": "La nostra storia",
  "image": "../images/iveco.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`3M Trasporti si trova a Serravalle nella Repubblica di San Marino e si occupa di trasporti terrestri. `}</p>
    <p>{`Siamo un’azienda giovane e dinamica andiamo incontro alle effettive esigenze del mercato odierno offrendo un servizio qualitativamente elevato a prezzi competitivi.`}</p>
    <p>{`Portiamo a destinazione ogni tipo di merce ad eccezione di refrigerati ed animali vivi.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      